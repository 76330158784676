<template>
  <div>
    <b-modal
      class="px-0"
      @hidden="$emit('update:is-table-open', false)"
      :visible="isTableOpen"
      id="modal-attendance"
      hide-footer
      centered
      size="xl"
      body-class="p-0"
      :title="`Take Attendance for ${courseSectionData.name}`"
    >
      <div>
        <div class="content-px mb-1">
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
                  <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 28rem" />
                </div>
                <b-button @click="$refs.refAttendanceTable.refresh()">Refresh</b-button>
                <b-form-checkbox class="ml-5 custom-control-primary" v-model="isFocusEnrolled" switch>Focus</b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          v-if="isTableActive"
          id="table-attendance"
          ref="refAttendanceTable"
          class="position-relative modal-attendance"
          :items="fetchAttendance"
          responsive
          :fields.sync="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          sticky-header="70vh"
          :busy="isTableLoading"
        >
          <template #thead-top="data">
            <b-tr>
              <b-th colspan="1" class="attendance-course"><span class="sr-only">Student</span></b-th>
              <b-th
                class="attendance-course"
                :variant="index % 2 === 0 ? `primary` : 'warning'"
                :key="course.id"
                v-for="(course, index) in addlHeader"
                :colspan="course.classes.length || 1"
              >
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar size="24" :src="course.avatar" :text="avatarText(course.name)" :variant="`light-info`" />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ course.name }}
                  </span>
                </b-media>
              </b-th>
            </b-tr>
          </template>

          <template v-for="field in dynamicTableColumns" v-slot:[`head(${field.key})`]="{ item }">
            <div class="d-inline-flex">
              {{ field.label }}
              <b-button
                v-if="false"
                size="sm"
                class="cursor-pointer badge badge-pill"
                style="margin-left: 5px"
                variant="white"
                @click="openCheckinModal(field.key, field.label)"
                >+</b-button
              >
            </div>
          </template>

          <template #cell(student.fullName)="data">
            <div class="text-nowrap">
              <div class="d-flex align-items-center w-100" style="gap: 0.625rem">
                <b-avatar
                  size="36"
                  :src="data.item.student.avatar"
                  :text="avatarText(`${data.item.student.firstName} ${data.item.student.lastName}`)"
                  :variant="`light-info`"
                  :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
                />
                <div class="d-flex flex-column w-100" style="gap: 0">
                  <div class="d-flex justify-content-between align-items-center">
                    <b-link
                      :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
                      class="font-weight-bold text-nowrap text-white mb-0"
                      style="font-size: 1.25rem; line-height: 1.75rem; margin-right: 1rem"
                    >
                      {{ data.item.student.fullName }} <b-badge v-if="data.item.status === 'reassigned'" variant="warning"> R </b-badge>
                    </b-link>
                    <router-link
                      :to="{
                        name: 'apps-roster-profile-edit',
                        params: { id: data.item.profile.id },
                        query: { section: courseSectionData.id },
                      }"
                    >
                      <b-badge
                        :variant="`${resolveProfileSectionStatusVariant(sectionField(data.item, 'status'))}`"
                        class="text-capitalize"
                      >
                        {{ title(sectionField(data.item, 'status')) }}
                      </b-badge>
                    </router-link>
                  </div>
                  <div v-if="data.item.session.type === 'course'" class="text-muted text-nowrap">
                    <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 3px" />
                    <span>Direct Enrollment</span>
                  </div>
                  <div v-if="data.item.session.type === 'pathway'" class="text-muted text-nowrap">
                    <lucide-icon name="Library" style="width: 14px; height: 14px; margin-right: 3px" />
                    <span>{{ data.item.session.pathway.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-for="field in dynamicTableColumns" v-slot:[`cell(${field.key})`]="{ item }">
            <div
              v-for="(checkin, index) in (item.record[field.key] || { checkins: [] }).checkins"
              class="form-check form-check-inline float-right text-center"
              style="margin-bottom: 4px"
            >
              <template v-if="checkin.type === 'credited'">
                <span class="text-nowrap">
                  {{ momentTz(checkin.date).format('h:mm A') }} <b-badge class="ml-1" variant="secondary">ToC</b-badge>
                </span>
              </template>
              <template v-else-if="checkin.type === 'reassigned' || checkin.type === 'replaced'">
                <span class="text-nowrap">
                  {{ momentTz(checkin.date).format('h:mm A') }} <b-badge class="ml-1" variant="warning">R</b-badge>
                </span>
              </template>
              <template v-else-if="checkin.type === 'withdrew'">
                <span class="text-nowrap">
                  {{ momentTz(checkin.date).format('h:mm A') }} <b-badge class="ml-1" variant="danger">W</b-badge>
                </span>
              </template>
              <template v-else-if="checkin.type === 'terminated'">
                <span class="text-nowrap">
                  {{ momentTz(checkin.date).format('h:mm A') }} <b-badge class="ml-1" variant="danger">T</b-badge>
                </span>
              </template>

              <template v-else>
                <span class="text-nowrap">{{ momentTz(checkin.date).format('h:mm A') }}&nbsp;</span>

                <b-form-checkbox
                  class="custom-control-primary"
                  @change="updateAttendanceRecord(item)"
                  v-model="checkin.attended"
                  :disabled="checkin.type === 'credited'"
                />

                <b-button
                  variant="primary"
                  class="btn-sm edit-btn"
                  :variant="resolveCheckinVariant(checkin)"
                  @click="openSubModal(item, field.key, index)"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
              </template>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-75">
          <b-row>
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
              <div class="mr-2 vue-select-up">
                <span class="mb-0 mr-75">Rows Per Page</span>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block"
                  :open="true"
                />
              </div>
              <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalAttendances"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-sm-0 ml-lg-50"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-attendance-attributes"
      :title="createTitleForModal()"
      ok-title="Save Changes"
      cancel-variant="outline-secondary"
      ref="attendance-sub-modal"
      @ok="saveSubModal"
      :ok-disabled="(attendanceRecord.type === 'absent_excused' && !attendanceRecord.notes) || attendanceRecord === 'reassigned'"
      centered
    >
      <b-form class="p-1">
        <b-form-group label="Attended?" label-for="attendance-attended">
          <v-select
            transition=""
            id="attendance-attended"
            v-model="attendanceRecord.attended"
            :reduce="(val) => val.value"
            :options="attendedOptions"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Type" label-for="attendance-type">
          <v-select
            transition=""
            id="attendance-type"
            v-model="attendanceRecord.type"
            :reduce="(val) => val.value"
            :options="typeOptions"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Notes" label-for="attendance-notes">
          <b-form-textarea v-model="attendanceRecord.notes" id="attendance-notes" />
        </b-form-group>

        <component is="div" v-if="attendance.profile && attendanceRecord.type === 'reassigned' && attendance.profile.reassignReason">
          <hr />
          <b-form-group label="Reassign Reason" label-for="reassign-reason">
            <b-form-textarea v-model="attendance.profile.reassignReason" disabled id="reassign-reason" />
          </b-form-group>
        </component>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-attendance-checkin"
      :title="`Create Checkin`"
      ok-title="Create"
      cancel-variant="outline-secondary"
      ref="attendance-checkin-modal"
      @ok="saveCheckinModal"
      centered
    >
      <b-form class="p-1">
        <b-form-group label="Time of Checkin" label-for="attendance-checkin-time">
          <b-form-timepicker :minutes-step="15" :now-button="true" id="attendance-checkin-time" v-model="createCheckin.time" />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BTr,
  BTh,
  BCard,
  BCardText,
  BButton,
  BAvatar,
  BMedia,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BFormTimepicker,
  BTable,
  BPagination,
  BLink,
  BBadge,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import { onUnmounted, ref } from '@vue/composition-api';
import store from '@/store';
import rosterStoreModule from '../../../../../../../store/roster/rosterStoreModule';
import { avatarText, title } from '@core/utils/filter';
import { momentTz } from '@/libs/utils/time';
import useProfiles from '@/views/apps/roster/profile/useProfiles';
import useCourseSectionAttendance from '@/views/apps/roster/courses/sections/view/components/useCourseSectionAttendance';

export default {
  name: 'AttendanceModal',
  directives: {
    Ripple,
  },
  components: {
    BTr,
    BTh,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    vSelect,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,
    BTable,
    BPagination,
    BLink,
    BBadge,
  },
  props: {
    courseSectionData: {
      type: Object,
    },
    isTableOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      attendedOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      typeOptions: [
        { label: 'Present', value: 'present' },
        { label: 'Absent - Excused', value: 'absent_excused' },
        { label: 'Absent - Unexcused', value: 'absent_unexcused' },
        { label: 'Late', value: 'late' },
        { label: 'Special Case', value: 'special_case' },
        // { label: 'Reassigned', value: 'reassigned' },
        // { label: 'Credited', value: 'credited' },
      ],
      createCheckin: {},
      isTimestampEnabled: true,
      isTableLoading: false,

      userData: JSON.parse(localStorage.getItem('userData')),
    };
  },
  methods: {
    sectionField(profile, field) {
      const id = this.courseSectionData.id;

      if (!profile.profile.sections || !profile.profile.sections[id]) {
        return 'na';
      }

      return profile.profile.sections[id][field];
    },
    variantOfStatus(status) {
      return ['terminated', 'withdrew_s1', 'withdrew_s2', 'withdrew_s3', 'action_required'].includes(status)
        ? 'info'
        : ['denial_of_benefit'].includes(status)
        ? 'warning'
        : 'info';
    },
    title,
    openCheckinModal(classId, dateLabel) {
      this.createCheckin = { classId, date: dateLabel, time: '12:00:00' };
      this.$refs['attendance-checkin-modal'].show();
    },
    async saveCheckinModal() {
      this.isTableLoading = true;
      const f = momentTz(`${this.createCheckin.date} ${this.createCheckin.time}`);
      return store
        .dispatch('rosterStoreModule/createSectionClassCheckin', {
          sectionId: this.courseSectionData.id,
          classId: this.createCheckin.classId,
          dateTime: f.format(),
        })
        .then(() => {
          this.isTableLoading = false;
          setTimeout(this.refetchData, 0);
        })
        .catch((err) => {
          this.isTableLoading = false;
          console.error('failed to update attendance', err);
        });
    },
    createTitleForModal() {
      if (!this.attendanceRecord) return 'Record not loaded';
      return `Update Attendance for \n${momentTz(this.attendanceRecord.date).format('L h:mmA')}`;
    },
    openSubModal(item, classId, checkinIndex) {
      this.attendance = { ...item };
      console.log('opening modal for obj', item.record[classId].checkins[checkinIndex]);
      this.attendanceRecord = { id: classId, index: checkinIndex, ...item.record[classId].checkins[checkinIndex] };
      this.$refs['attendance-sub-modal'].show();
    },
    resolveCheckinVariant(checkin) {
      if (checkin.type === 'absent_unexcused') return 'danger';
      if (checkin.type === 'absent_excused' || checkin.type === 'late') return 'warning';
      if (checkin.type === 'special_case') return 'info';
      if (checkin.type === 'present') return 'primary';
      return 'info';
    },

    async saveSubModal() {
      const record = { ...this.attendanceRecord };
      const { id: classId, index: checkinIndex } = record;
      delete record.id;
      delete record.index;

      if (this.attendance.record[classId].checkins[checkinIndex].type !== record.type) {
        record.typeBy = this.userData.id;
      }

      this.attendance.record[classId].checkins[checkinIndex] = record;

      try {
        await this.updateAttendanceRecord(this.attendance);
        this.$refs['attendance-sub-modal'].hide();
      } catch (e) {}

      this.attendance = {};
      this.attendanceRecord = { ...this.attendanceDefault };

      this.refetchData();
    },
  },
  setup(props, {}) {
    const attendanceDefault = ref({ type: 'present', attended: false, notes: '', date: '' });

    const attendance = ref({});
    const attendanceRecord = ref({ type: 'present', attended: false, notes: '', date: '' });

    const isSubModalOpen = ref(false);
    const isTableActive = ref(false);

    const {
      refAttendanceTable,
      fetchAttendance,
      tableColumns,
      dynamicTableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalAttendances,
      refetchData,
      sortBy,
      isSortDirDesc,
      dataMeta,
      searchQuery,
      isFocusEnrolled,
      totalPageCount,
    } = useCourseSectionAttendance(props.courseSectionData);

    const { resolveProfileSectionStatusVariant } = useProfiles();

    const addlHeader = ref([]);

    store
      .dispatch('rosterStoreModule/fetchCourseSectionAttendanceTable', { id: props.courseSectionData.id })
      .then((response) => {
        const data = response.data;

        console.log('setting columns:');
        console.log('receivabled table', data);

        const { columns, sections } = data;

        console.log(data);
        for (const section of sections) {
          addlHeader.value.push(section);
        }

        tableColumns.push(...columns);

        let isInBefore = true;
        dynamicTableColumns.push(
          ...columns.map((col) => {
            if (col.label === momentTz().format('L')) {
              col.thAttr = { id: 'col-scroll' };
              col.variant = 'info';
              col.isToday = true;
            } else if (isInBefore && momentTz(col.label).isAfter(momentTz())) {
              isInBefore = false;
              col.thAttr = { id: 'col-scroll' };
            }

            col.sortable = false;
            col.thClass = 'no-overlap';
            return col;
          }),
        );
        isTableActive.value = true;

        // setTimeout(() => {
        //   const elem = document.getElementById('col-scroll');
        //   if (elem) elem.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'end' });
        // }, 500);
      })
      .catch((err) => {
        console.log(err);
        console.error('failed to fetch table', err);
      });

    fetchAttendance({}, (cb) => {
      console.log('final', cb);
    });

    const updateAttendanceRecord = async (item) => {
      return store.dispatch('rosterStoreModule/updateSessionAttendance', { id: item.id, data: { record: item.record } }).catch((err) => {
        console.error('failed to update attendance', err);
      });
    };

    return {
      refAttendanceTable,
      fetchAttendance,
      tableColumns,
      dynamicTableColumns,
      addlHeader,
      perPage,
      perPageOptions,
      currentPage,
      totalAttendances,
      refetchData,
      sortBy,
      isSortDirDesc,
      avatarText,
      dataMeta,
      searchQuery,

      attendanceDefault,
      attendance,
      attendanceRecord,
      isTableActive,
      isFocusEnrolled,

      updateAttendanceRecord,
      isSubModalOpen,
      totalPageCount,
      momentTz,

      resolveProfileSectionStatusVariant,
    };
  },
};
</script>
<style lang="scss">
.modal-attendance {
  table {
    tr {
      > th {
        top: 35px !important;
      }
    }
    .attendance-course {
      top: -5px !important;
      z-index: 9 !important;
    }
  }
  .edit-btn {
    padding: 0.25rem;
  }
}
</style>
