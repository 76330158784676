<template>
  <div>
    <div
      @contextmenu.prevent="$refs.courseSectionViewEditMenu.open"
      :style="`${calculateBorderColor(courseColor)} padding: 0.075rem`"
      class="rounded position-relative overflow-hidden drop-shadow-default"
    >
      <img class="d-none d-md-flex" style="position: absolute; right: 0; top: 0; width: 100%" :src="courseImage" v-if="courseImage" />
      <div class="rounded" style="background: #191a22">
        <div class="card-body rounded z-2 position-relative" :style="calculateBackgroundColor(courseColor)">
          <div class="d-flex align-items-center">
            <b-avatar
              :src="courseSectionData.course.avatar"
              :text="avatarText(courseSectionData.course.name)"
              size="80px"
              variant="light-info"
              style="margin-top: 1px"
              class="mr-1"
              rounded
            />
            <div class="d-flex flex-column align-items-start justify-content-center">
              <b-link
                :to="{ name: 'apps-roster-course-view', params: { id: courseSectionData.course.id } }"
                class="d-flex align-items-center text-white font-weight-light"
              >
                <small class="" style="font-size: 1.15rem; line-height: 1.35rem; margin-bottom: 1px">{{
                  courseSectionData.course ? courseSectionData.course.name : courseSectionData.name
                }}</small>
              </b-link>

              <div class="d-flex align-items-center mb-25">
                <h4 class="font-weight-bolder tracking-tighter mr-50" style="font-size: 1.85rem; line-height: 2.15rem; margin-bottom: 2px">
                  {{ resolveCourseSectionName(courseSectionData) }}
                </h4>
                <feather-icon
                  v-if="$can('roster.section.update')"
                  icon="EditIcon"
                  size="13"
                  class="cursor-pointer text-muted"
                  style="margin-bottom: 2px"
                  @click="$emit('update:edit-course-section-active', true)"
                />
              </div>

              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <b-badge class="profile-badge mr-25" :variant="`${resolveCourseSectionStatusVariant(courseSectionData.status)}`">
                    <feather-icon :icon="resolveCourseSectionStatusIcon(courseSectionData.status)" size="12" />
                    {{ title(courseSectionData.status) }}
                  </b-badge>
                  <b-badge class="profile-badge" :variant="`light-${resolveCourseSectionVisibilityVariant(courseSectionData.visibility)}`">
                    <feather-icon :icon="resolveCourseSectionVisibilityIcon(courseSectionData.visibility)" size="12" />
                    {{ title(courseSectionData.visibility) }}
                  </b-badge>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-context ref="courseSectionViewEditMenu" class="mw-8">
      <li class="v-context__sub">
        <a v-if="$can('roster.section.update.compliance')">
          <span> Compliance </span>
        </a>
        <ul class="v-context">
          <li>
            <b-link class="d-flex align-items-center justify-content-between" @click="syncCompliance">
              <span>Sync</span>
              <feather-icon icon="RefreshCwIcon" size="15" class="ml-50 text-muted" />
            </b-link>
          </li>
          <li>
            <b-link class="d-flex align-items-center justify-content-between" @click="createComplianceReports('all')">
              <span>Reports - All</span>
              <feather-icon icon="RefreshCwIcon" size="15" class="ml-50 text-muted" />
            </b-link>
          </li>
          <li>
            <b-link class="d-flex align-items-center justify-content-between" @click="createComplianceReports('final')">
              <span>Reports - Final</span>
              <feather-icon icon="RefreshCwIcon" size="15" class="ml-50 text-muted" />
            </b-link>
          </li>
        </ul>
      </li>
      <li class="v-context__sub">
        <a v-if="$can('roster.section.update.compliance')">
          <span> MyIP </span>
        </a>
        <ul class="v-context">
          <li>
            <b-link class="d-flex align-items-center justify-content-between" @click="sendOnboardingToSection">
              <span>Invite</span>
              <feather-icon icon="InviteIcon" size="15" class="ml-50 text-muted" />
            </b-link>
          </li>
        </ul>
      </li>
      <li>
        <b-link
          v-if="$can('roster.section.update')"
          class="d-flex align-items-center justify-content-between"
          @click="$emit('update:edit-course-section-active', true)"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </b-link>
      </li>
      <li>
        <b-link v-if="$can('roster.section.delete')" class="d-flex align-items-center justify-content-between" @click="archiveCourse">
          <span>Archive</span>
          <feather-icon icon="TrashIcon" size="15" class="ml-50 text-muted" />
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import { BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow } from 'bootstrap-vue';
import { avatarText, title } from '@core/utils/filter';
import useCourseSections from '../useCourseSections';
import useAppConfig from '@core/app-config/useAppConfig';
import VueContext from 'vue-context';
import store from '@/store';
import { hexToRGB } from '@/libs/utils/color';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const { skin } = useAppConfig();

export default {
  components: {
    BLink,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    VueContext,
    ToastificationContent,
  },
  props: {
    courseSectionData: {
      type: Object,
      required: true,
    },
    editCourseSectionActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    courseColor() {
      if (
        this.courseSectionData.course.metadata &&
        this.courseSectionData.course.metadata.design &&
        this.courseSectionData.course.metadata.design.color
      ) {
        return this.courseSectionData.course.metadata.design.color;
      }
      return '#5b5b5e';
    },
    courseImage() {
      if (
        this.courseSectionData.course.metadata &&
        this.courseSectionData.course.metadata.design &&
        this.courseSectionData.course.metadata.design.image
      ) {
        return this.courseSectionData.course.metadata.design.image;
      }
      return null;
    },
  },
  methods: {
    syncCompliance() {
      store
        .dispatch('rosterStoreModule/syncCourseSectionCompliance', {
          sectionId: this.courseSectionData.id,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Began compliance sync',
              icon: 'CheckIcon',
              variant: 'success',
            },
          });

          this.$emit('refetch-data');
        });
    },
    createComplianceReports(mode) {
      if (mode === 'final') {
      } else if (mode === 'all') {
      } else {
        throw new Error('Invalid mode selected');
      }

      store
        .dispatch('rosterStoreModule/createSectionComplianceReports', {
          sectionId: this.courseSectionData.id,
          mode,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Began compliance report generation for applicable students',
              icon: 'CheckIcon',
              variant: 'success',
            },
          });

          this.$emit('refetch-data');
        });
    },
    sendOnboardingToSection() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to send onboarding to the students in this section?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Send',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/sendOnboardingToSection', {
              sectionId: this.courseSectionData.id,
            })
            .then((response) => {
              this.$emit('refetch-data');

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sent MyIP Onboarding!',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              });
            });
        }
      });
    },
    archiveCourse() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to archive this course section?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/updateCourseSection', {
              courseSectionId: this.courseSectionData.id,
              courseSectionData: { status: 'archived' },
            })
            .then((response) => {
              this.$emit('refetch-data');
            });
        }
      });
    },
    calculateBackgroundColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.35) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.05) 93.34%)`;
    },
    calculateBorderColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(190deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.03) 100%);`;
    },
  },
  metaInfo() {
    const title = this.resolveCourseSectionName(this.courseSectionData) || 'View Course Section';
    return {
      title,
    };
  },
  setup() {
    const {
      resolveCourseSectionStatusVariant,
      resolveCourseSectionStatusIcon,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionVisibilityIcon,
      resolveCourseSectionName,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageVariant,
      resolveCourseSectionDateMessageIcon,
    } = useCourseSections();
    return {
      avatarText,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionStatusIcon,
      resolveCourseSectionVisibilityIcon,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionName,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageVariant,
      resolveCourseSectionDateMessageIcon,
      title,
      skin,
    };
  },
};
</script>

<style></style>
